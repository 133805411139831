<template>
    <div>
        <!-- Start Modal Quickview cart -->
        <div
            class="modal fade"
            id="modalQuickview"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col text-right">
                                    <button
                                        type="button"
                                        class="close modal-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">
                                            <i class="fa fa-times"></i
                                        ></span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="product-details-gallery-area mb-7">
                                        <!-- Start Large Image -->
                                        <div v-if="images && images.length > 0">
                                            <div class="product-large-image modal-product-image-large swiper-container">
                                                <swiper
                                                :modules="modules"
                                                :spaceBetween="10"
                                                :navigation="true"
                                                :thumbs="{ swiper: thumbsSwiper }"
                                                class="swiper-wrapper"
                                                >
                                                    <swiper-slide

                                                        v-for="(
                                                            media, index
                                                        ) in images"
                                                        :key="index"
                                                    >
                                                        <div class="product-image-large-image swiper-slide img-responsive">
                                                            <img
                                                                :src="media.url"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </swiper-slide>
                                                </swiper>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="product-large-image modal-product-image-large swiper-container">
                                                <div class="swiper-wrapper">
                                                    <div class="product-image-large-image swiper-slide img-responsive">
                                                        <img :src="getPath('assets/images/dummy.jpg')" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Large Image -->
                                        <!-- Start Thumbnail Image -->
                                        <div v-if="images && images.length > 0">
                                            <div class="product-image-thumb modal-product-image-thumb swiper-container pos-relative mt-5">
                                                <swiper
                                                    :spaceBetween="10"
                                                    :slidesPerView="4"
                                                    :freeMode="true"
                                                    :watchSlidesProgress="true"
                                                    :modules="modules"
                                                    @swiper="setThumbsSwiper"
                                                    @slideChange="setThumbsSwiper"
                                                    class="swiper-wrapper"
                                                >
                                                    <swiper-slide
                                                        v-for="(
                                                            media, index
                                                        ) in images"
                                                        :key="index"
                                                    >
                                                        <div  class="product-image-thumb-single swiper-slide">
                                                            <img
                                                                class="img-fluid"
                                                                :src="media.url"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </swiper-slide>
                                                </swiper>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="product-image-thumb modal-product-image-thumb swiper-container pos-relative mt-5">
                                                <div class="swiper-wrapper">
                                                    <div class="product-image-thumb-single swiper-slide">
                                                        <img class="img-fluid" :src="getPath('assets/images/dummy.jpg')" alt="" style="max-height: 100px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Thumbnail Image -->
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div
                                        class="modal-product-details-content-area"
                                    >
                                        <!-- Start  Product Details Text Area-->
                                        <div class="product-details-text">
                                            <h4 class="title">
                                                {{ data.name }}
                                            </h4>
                                            <div class="price">
                                                <span
                                                    class="badge bg-secondary"
                                                    v-for="
                                                        category
                                                     in data.categories"
                                                    :key="category.id"
                                                >
                                                    {{ category.name }}

                                                </span>
                                            </div>
                                        </div>
                                        <!-- End  Product Details Text Area-->
                                        <!-- Start Product Variable Area -->
                                        <div class="product-details-variable">
                                            <!-- Product Variable Single Item -->
                                            <div class="variable-single-item" v-if="data.variations && data.variations.length > 0">
                                                <span>Variantes</span>
                                                <table
                                                    class="available-options"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Código</th>
                                                            <th>Descripción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(
                                                                variation, index
                                                            ) in data.variations"
                                                            :key="index"
                                                        >
                                                            <td>
                                                                {{ variation.sku }}
                                                            </td>
                                                            <td>
                                                                {{ variation.name }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-if="allowBuy">
                                                <!-- Product Variable Single Item -->
                                                <div class="variable-single-item" v-if="data.variations && data.variations.length > 0">
                                                    <span>Opción</span>
                                                    <select class="product-variable-size " v-model="selectedVariation">
                                                        <option  v-for="(variation,k) in data.variations" :key="k" :value="variation.id" >
                                                            {{ variation.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- Product Variable Single Item -->
                                                <div class="d-flex align-items-center">
                                                    <div class="variable-single-item">
                                                        <span>Cantidad</span>
                                                        <div class="product-variable-quantity">
                                                            <v-plus-minus @update="updateQty" :start="1"></v-plus-minus>
                                                        </div>
                                                    </div>
                                                    <div class="product-add-to-cart-btn">
                                                        <button href="#" @click="addCart()" :disabled="allowAdd" style="white-space: nowrap;">+ Agregar al Carro</button>
                                                    </div>
                                                </div>
                                                <!-- End  Product Details Meta Area-->
                                                <div>
                                                    <div class="product-details-text" v-if="totalPrice > 0">
                                                        <div class="price">{{ totalPriceFormat }}</div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- Product Variable Single Item -->
                                            <div
                                                class="d-flex align-items-center flex-wrap"
                                            >
                                                <div
                                                    class="product-add-to-cart-btn"
                                                >
                                                    <a :href="data.link"
                                                        >Ver más detalles</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Product Variable Area -->
                                        <div class="modal-product-about-text">
                                            <p>
                                                {{ data.description }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { useCommonStore } from "../store/useCommonFrontend";
import { storageSet } from "../utils";
import vPlusMinus from "./vPlusMinus.vue";
export default defineComponent({
    name: "ModalProduct",
    components: {
        Swiper,
        SwiperSlide,
        vPlusMinus
    },
    props: {

        buy: {
            type: [String, Number],
            default: "0"
        }
    },
    setup(props, { emit }) {
        const common = useCommonStore();
        const thumbsSwiper = ref(null);
        const data = ref({});
        const images = ref([]);
        const selectedVariation = ref({})
        const finalPrice = ref(0)
        const stock = ref(0)
        const price = ref(0)
        const quantity = ref(1)
        const totalPrice = ref(0)
        const totalPriceFormat = ref('')
        const modules = ref([FreeMode, Navigation, Thumbs]);
        const allowAdd = ref(true)
        const modal = ref(null)
        const allowBuy = computed(() => props.buy === 1? true : false)
        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        watch( selectedVariation,(newValue, oldValue) => {
            updatePrice()
        })
        const updatePrice = () => {
            const finalPrices =  document.querySelectorAll(".final-price-show");
            if(data.value.variations.length > 0) {
                const index = data.value.variations.findIndex((x) => {
                    return parseInt(x.id,10) === selectedVariation.value
                })
                if(index > -1){
                    finalPrices.forEach((x) => {
                        x.textContent =  common.formatCurrency(data.value.variations[index].price, data.value.variations[index].currency_id)
                    })
                    price.value = data.value.variations[index].price
                    finalPrice.value = common.formatCurrency(data.value.variations[index].price, data.value.variations[index].currency_id)
                    stock.value = data.value.variations[index].stock
                }
            } else {
                finalPrices.forEach((x) => {
                    x.textContent = common.formatCurrency(data.value.price, data.value.currency_id)
                })
                price.value = data.value.price
                finalPrice.value = common.formatCurrency(data.value.price, data.value.currency_id)
                stock.value = data.value.stock
            }
            totalPrice.value = price.value * quantity.value
            totalPriceFormat.value = common.formatCurrency(totalPrice.value, data.value.currency_id)
            if(parseInt(stock.value,10) > 0 && (parseInt(stock.value,10) >= parseInt(quantity.value,10))) {
                allowAdd.value = false
            }else{
                allowAdd.value = true
            }
        }
        watch( quantity,(newValue) => {
            if(newValue < 1) {
                quantity.value = 1
            }
            updatePrice()
        })
        const getPath = ( image) => {
            return  `${String(process.env.MIX_APP_HOST)}${image}`
        }
        const addCart = async () => {
            var myData = {
                variation_id: null,
                quantity: quantity.value,
            }
            if(!allowAdd.value) {
                if(data.value.variations.length > 0) {
                    myData.variation_id = selectedVariation.value
                }
                const response = common.addProductCart(data.value.slug, myData)
                response.then((x) => {
                    if(x.data.error){
                        alert('Ha ocurrido un error')
                    }else{
                        getRecords()
                    }
                })

            } else {
                alert('No puede realizarse la transacción')
            }
        }
        const getRecords = async () => {
            modal.value.hide();
            common.updateCartItems();
        }
        const updateQty = (payload) => {
            quantity.value = payload.quantity
        }
        onMounted(() => {
            document.addEventListener("DOMContentLoaded", function () {
                modal.value = new bootstrap.Modal(
                    document.getElementById("modalQuickview")
                );
                const modalTriggerElements =  document.querySelectorAll(".modal-trigger"); // Get all elements with the class
                // Attach an event listener to each modal trigger element
                modalTriggerElements.forEach(function (element) {
                    if(element) {
                        element.addEventListener("click", async function (ev) {
                            ev.preventDefault();
                            const slug = element.getAttribute("data-product-id");
                            var url = `${String(process.env.MIX_SENTRY_HOST)}product/${slug}`;
                            const response = await common.store({url, data: {}})
                            data.value = response.data;
                            images.value = response.data.multimedia
                            modal.value.show();
                            updatePrice()
                        });
                    }
                });
            });
        });

        return {
            data,
            images,
            thumbsSwiper,
            selectedVariation,
            stock,
            price,
            quantity,
            totalPrice,
            totalPriceFormat,
            allowAdd,
            modal,
            allowBuy,
            getRecords,
            addCart,
            getPath,
            setThumbsSwiper,
            updateQty,
            modules
        };
    },
});
</script>
