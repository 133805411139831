<template>
    <div>
        <!-- Start Modal Add cart -->
        <div
            class="modal fade"
            id="modalErrorId"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            >
            <div class="modal-dialog  modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col text-right">
                                    <button type="button"
                                        class="close modal-close"
                                        @click="handleClose"
                                        aria-label="Close">
                                        <span aria-hidden="true">
                                            <i class="fa fa-times"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p>&nbsp;</p>
                                    <div class="alert alert-info">
                                        <h5>{{  message }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal Add cart -->
    </div>
</template>
<script>
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
export default defineComponent({
    name: "ModalEror",
    setup() {
        const common = useCommonStore();
        const modal = ref({})
        const message = ref(0)
        const errorMessage = computed(() => {
            return common.state.errorMessage
        });
        const handleClose = () => {
            common.setMessageError('')
        }
        watch(errorMessage, (v1) => {
            if (v1 && v1 !== '') {
                message.value = v1;
                modal.value.show();
            }else{
                modal.value.hide();
            }
        })

        common.$subscribe((mutation, state) => {
            console.log('a change happened')
            console.log(mutation, state)
        })

        onMounted(() => {
            modal.value = new bootstrap.Modal(
                document.getElementById("modalErrorId")
            );
            common.setMessageError('')
        })
        return {
            common,
            errorMessage,
            message,
            handleClose
        };
    },
});
</script>
