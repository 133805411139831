<template>
    <!-- ...:::: Start Checkout Section:::... -->
    <div class="checkout-section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <details-module
                        :items="items"
                        :extra="extra"
                    ></details-module>
                </div>
            </div>
            <div class="row" v-if="customer === null">
                <div class="col-12">
                    <!-- User Quick Action Form -->
                    <login-module :message="loginMessage"></login-module>
                    <!-- User Quick Action Form -->
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12">
                    Nombre: <strong>{{ customer.name }}</strong>
                    <br />
                </div>
            </div>
            <!-- Start User Details Checkout Form -->
            <div class="checkout_form" data-aos="fade-up" data-aos-delay="400">
                <div class="row">
                    <div class="col-12">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value="1"
                                id="sameddress"
                                v-model="sameAddress"
                            />
                            <label
                                class="form-check-label"
                                for="flexCheckDefault"
                            >
                                Utilizar la misma dirección para facturación
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div :class="!sameAddress ? 'col-md-6' : 'col-12'">
                        <shipping-module
                            :provinces="provinces"
                            :shippings="shippings"
                            :errors="errorsShipping"
                            @update="updateShipping"
                            @change="changeShipping"
                        ></shipping-module>
                    </div>
                    <div class="col-lg-6 col-md-6" v-if="!sameAddress">
                        <billing-module
                            :provinces="provinces"
                            :billings="billings"
                            :errors="errorsBilling"
                            @update="updateBilling"
                        ></billing-module>
                    </div>
                </div>

                <div class="row" v-if="shippingsAmounts.length > 0">
                    <div class="col-12">
                        <h3>Costos de envío</h3>
                        <div
                            class="p-2"
                            v-for="(shipp, id1) in shippingsAmounts"
                            :key="id1"
                        >
                            <div><strong>{{ shipp.business }}</strong></div>
                            <p>{{ shipp.description }}</p>
                            <ul>
                                <li v-for="(amount, idx2) in shipp.options" :key="idx2">
                                    <label
                                        class="form-check-label"
                                        :for="`amount${amount.code}`"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="amountshipping"
                                            :id="`amount${amount.code}`"
                                            :value="amount"
                                            style="margin-right: 1rem"
                                            v-model="amountShipping"
                                        />

                                        {{ amount.description }} <b v-if="amount.price > 0"> - ({{
                                            formatCurrency(  amount.price, shipp.currency_id)
                                        }})</b
                                        >
                                    </label>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

                <div class="row" v-if="errorsPayment.length > 0">
                    <div class="col-12">
                        <div class="alert alert-danger">
                            <div
                                v-for="(error, idx) in errorsPayment"
                                :key="idx"
                            >
                                {{ error }}
                            </div>
                        </div>
                    </div>
                </div>
                <payments-list
                    :payments="payments"
                    @update="updatePaymentMethod"
                >
                </payments-list>
                <div class="row justify-contents-center align-items-center">
                    <div class="col-12 text-center">
                        <button
                            class="btn btn-md btn-black-default-hover btn-lg btn-primary"
                            type="button"
                            @click="sendInfo"
                        >
                            Proceder al pago
                        </button>
                    </div>
                </div>
            </div>
            <!-- Start User Details Checkout Form -->
        </div>
        <modal-message
            :message="message"
            :messageType="messageType"
            :enable="messageEnable"
            @close="closeMessage"
        ></modal-message>
    </div>
    <!-- ...:::: End Checkout Section:::... -->
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import { useNotification } from "@kyvg/vue3-notification";
import LoginModule from "./checkout/Login.vue";
import BillingModule from "./checkout/Billing.vue";
import ShippingModule from "./checkout/Shipping.vue";
import DetailsModule from "./checkout/Details.vue";
import PaymentsList from "./checkout/Payments.vue";
import ModalMessage from "./ModalMessage.vue";
export default defineComponent({
    name: "CheckoutPanel",
    props: {
        sessionid: {
            type: String,
            default: "",
        },
    },
    components: {
        LoginModule,
        BillingModule,
        ShippingModule,
        DetailsModule,
        PaymentsList,
        ModalMessage,
    },
    setup(props) {
        const common = useCommonStore();
        const { notify } = useNotification();
        const loginMessage = ref(false);
        const message = ref(null);
        const messageType = ref(null);
        const messageEnable = ref(false);
        const provinces = ref([]);
        const payments = ref([]);
        const customer = ref(null);
        const paymentMethod = ref(null);
        const shippingsAmounts = ref([]);
        const sameAddress = ref(true);
        const selectedShipping = ref(null);
        const billing = ref({
            id: null,
            provincia_id: null,
            name: null,
            cuit: null,
            company: null,
            city: null,
            postalcode: null,
            billing_phone: null,
            billing_reference: null,
            address_reference: null,
            selectedBilling: null,
        });
        const billings = ref(null);
        const shipping = ref({
            id: null,
            name: null,
            email: null,
            provincia_id: null,
            receiver_reference: null,
            receiver_dni: null,
            city: null,
            postalcode: null,
            receiver_phone: null,
            address: null,
            address_reference: null,
            selectedShipping: null,
        });
        const shippings = ref(null);
        const extra = ref({});
        const errorsBilling = ref([]);
        const errorsShipping = ref([]);
        const errorsPayment = ref([]);
        const amountShipping = ref(null);

        const items = computed(() => {
            return common.state.cartItems;
        });
        //Auxiliares
        const getProvinces = async () => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}checkout/provinces`;
            const response = await common.load({ url, data: {} });
            provinces.value = response.data;
        };
        const getPaymentsMethods = async () => {
            var url = `${String(process.env.MIX_SENTRY_HOST)}checkout/payments`;
            const response = await common.store({ url, data: {} });
            payments.value = response.data;
        };
        const getCustomerInfo = async () => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}checkout/customerInfo`;
            const response = await common.store({ url, data: {} });
            if (response.data === "") {
                customer.value = null;
                billings.value = null;
                shippings.value = null;
            } else {
                customer.value = response.data;
                billings.value = response.data.billings;
                shippings.value = response.data.shippings;
            }
        };
        const updateBilling = (bill) => {
            billing.value = bill;
        };
        const updateShipping = async (ship) => {
            shipping.value = ship;
            if (
                ship.postalcode &&
                ship.postalcode !== "" &&
                ship.postalcode !== null &&
                ship.postalcode.length > 3
            ) {
                // Cargamos los datos de envio
                var url = `${String(
                    process.env.MIX_SENTRY_HOST
                )}checkout/shippingcalculation`;
                const payload = {
                    postalcode: ship.postalcode,
                };
                const response = await common
                    .store({ url, data: payload })
                    .then((x) => {
                        shippingsAmounts.value = x.data;
                        amountShipping.value = x.data[0];
                    });
            }
        };
        const validate = () => {
            errorsBilling.value = [];
            errorsShipping.value = [];
            errorsPayment.value = [];
            if (selectedShipping.value !== null) {
            } else {
                shipping.value.id = null;
                if (
                    !(
                        shipping.value.receiver_reference &&
                        shipping.value.receiver_reference !== "" &&
                        shipping.value.receiver_reference !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar el nombre");
                }
                if (
                    !(
                        shipping.value.email &&
                        shipping.value.email !== "" &&
                        shipping.value.email !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar el correo electrónico");
                }
                if (
                    !(
                        shipping.value.receiver_dni &&
                        shipping.value.receiver_dni !== "" &&
                        shipping.value.receiver_dni !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar el dni");
                }
                if (
                    !(
                        shipping.value.city &&
                        shipping.value.city !== "" &&
                        shipping.value.city !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar la ciudad");
                }
                if (
                    !(
                        shipping.value.provincia_id &&
                        shipping.value.provincia_id !== "" &&
                        shipping.value.provincia_id !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar la provincia");
                }
                if (
                    !(
                        shipping.value.address &&
                        shipping.value.address !== "" &&
                        shipping.value.address !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar la dirección");
                }

                if (
                    !(
                        shipping.value.receiver_phone &&
                        shipping.value.receiver_phone !== "" &&
                        shipping.value.receiver_phone !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar un teléfono");
                }
                if (
                    !(
                        shipping.value.postalcode &&
                        shipping.value.postalcode !== "" &&
                        shipping.value.postalcode !== null
                    )
                ) {
                    errorsShipping.value.push("Debe ingresar el código postal");
                }
            }
            if (!sameAddress.value) {
                if (billing.value.selectedBilling !== null) {
                    billing.value.id = billing.value.selectedBilling;
                } else {
                    billing.value.id = null;
                    if (
                        !(
                            billing.value.name &&
                            billing.value.name !== "" &&
                            billing.value.name !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar el nombre");
                    }
                    if (
                        !(
                            billing.value.cuit &&
                            billing.value.cuit !== "" &&
                            billing.value.cuit !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar el cuit o Dni");
                    }
                    if (
                        !(
                            billing.value.city &&
                            billing.value.city !== "" &&
                            billing.value.city !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar la ciudad");
                    }
                    if (
                        !(
                            billing.value.postalcode &&
                            billing.value.postalcode !== "" &&
                            billing.value.postalcode !== null
                        )
                    ) {
                        errorsBilling.value.push(
                            "Debe ingresar el código postal"
                        );
                    }
                    if (
                        !(
                            billing.value.billing_phone &&
                            billing.value.billing_phone !== "" &&
                            billing.value.billing_phone !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar el teléfono");
                    }
                    if (
                        !(
                            billing.value.provincia_id &&
                            billing.value.provincia_id !== "" &&
                            billing.value.provincia_id !== null
                        )
                    ) {
                        errorsBilling.value.push(
                            "Debe seleccionar la provincia"
                        );
                    }
                }
            }
            if (amountShipping.value === null) {
                errorsShipping.value.push(
                    "Debe seleccionar una empresa para el envío"
                );
            }
            if (paymentMethod.value === null &&  payments.value.length > 1) {
                errorsPayment.value.push("Debe seleccionar un método de pago");
            }
            if (
                errorsBilling.value.length > 0 ||
                errorsShipping.value.length > 0 ||
                errorsPayment.value.length > 0
            ) {
                //console.log(errorsBilling.value);
                //console.log(errorsShipping.value);
                //console.log(errorsPayment.value);
                return false;
            }
            return true;
        };
        const sendInfo = async () => {
            if (validate()) {
                const payment2 = (paymentMethod.value) ? paymentMethod.value : payments.value[0].id
                const payload = {
                    billing: billing.value,
                    shipping: shipping.value,
                    sameaddres: sameAddress.value,
                    payment: payment2,
                    shippingcost: amountShipping.value,
                };
                var url = `${String(process.env.MIX_SENTRY_HOST)}checkout/init`;
                const response = await common
                    .store({ url, data: payload })
                    .then((x) => {
                        if (x.data.status == "success") {
                            if (x.data.data.redirect !== "") {
                                window.location.href = x.data.data.redirect;
                            } else {
                                messageEnable.value = true;
                                messageType.value = "success";
                                message.value =
                                    "Se ha registrado la compra satisfactoriamente";
                            }
                        } else {
                            messageEnable.value = true;
                            messageType.value = "error";
                            message.value =
                                "No ha podido procesarse la compra revise los datos";
                        }
                    });
            } else {
                messageEnable.value = true;
                messageType.value = "error";
                message.value =
                    "Revise los errores encontrados para poder finalizar su compra";
            }
        };
        const updatePaymentMethod = (payload) => {
            paymentMethod.value = payload.id;
        };

        const formatCurrency = (amount, currency_id) => {
            return common.formatCurrency(amount, parseInt(currency_id,10));
        };
        const changeShipping = (value) => {
            selectedShipping.value = value.id;
            if(shippings.value){
                const index = shippings.value.findIndex(
                    (x) =>
                        parseInt(x.id, 10) === parseInt(selectedShipping.value, 10)
                );
                if (index > -1) {
                    shipping.value.id = shippings.value[index].id;
                    shipping.value.postalcode = shippings.value[index].postalcode;
                    updateShipping(shipping.value);
                }
            }

        };
        const closeMessage = () => {
            messageEnable.value = false;
        };

        onMounted(() => {
            getCustomerInfo();
            getProvinces();
            getPaymentsMethods();
        });
        return {
            extra,
            items,
            billing,
            billings,
            shipping,
            shippings,
            provinces,
            customer,
            errorsBilling,
            errorsShipping,
            errorsPayment,
            loginMessage,
            payments,
            shippingsAmounts,
            amountShipping,
            sameAddress,
            selectedShipping,
            getCustomerInfo,
            updateBilling,
            updateShipping,
            getPaymentsMethods,
            updatePaymentMethod,
            sendInfo,
            formatCurrency,
            changeShipping,
            closeMessage,
            message,
            messageType,
            messageEnable,
        };
    },
});
</script>
