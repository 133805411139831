<template>
    <div>
        <ul class="offcanvas-cart" v-if="currencies.length > 0">
            <template v-if="items && items.length > 0">
            <li class="offcanvas-cart-item-single" v-for="(item, index) in items" :key="index">
                <div class="offcanvas-cart-item-block">
                    <a
                        :href="item.link"
                        class="offcanvas-cart-item-image-link"
                    >
                        <img
                            v-if="item.multimedia[0]"
                            :src="item.multimedia[0].url"
                            alt=""
                            class="offcanvas-cart-image"
                        />
                        <img
                            v-else
                            :src="getDummyImage()"
                            alt=""
                            class="offcanvas-cart-image"
                        />
                    </a>
                    <div class="offcanvas-cart-item-content">
                        <a
                            :href="item.link"
                            class="offcanvas-cart-item-link"
                            >{{ item.name }}</a
                        >
                        <div class="offcanvas-cart-item-details">
                            <span
                                class="offcanvas-cart-item-details-quantity"
                                ><strong> x {{ item.quantity }} </strong>
                            </span>
                            <span
                                class="offcanvas-cart-item-details-price"
                                >{{ formatCurrency(item.price, item.currency_id) }}</span
                            >
                        </div>
                    </div>
                </div>
                <div class="offcanvas-cart-item-delete text-end">
                    <a
                        href="#"
                        class="offcanvas-cart-item-delete"
                        @click="deleteRecord(item.slug)"
                        ><i class="fa fa-trash-o"></i
                    ></a>
                </div>
            </li>
            </template>
            <li v-else>
                <div class="alert alert-info">
                    No hay productos en el carro
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import { storageSet, getSiteURL } from "../utils";
export default defineComponent({
    props: {
        sessionid: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const common = useCommonStore();
        const itemsQuantity = ref(0);
        const product = ref({});
        const enableModal = ref(false);
        //const items = ref([])
        const sessionId = computed(() => props.sessionid);
        const currencies = computed(() => {
            return common.state.currencies
        });
        const items = computed(() => {
            return common.state.cartItems
        });

        watch(items, (value) => {
            itemsQuantity.value = value.length;
            const indicators = document.querySelectorAll(".item-count-cart");
            indicators.forEach(function (element) {
                element.textContent = parseInt(
                    itemsQuantity.value ? itemsQuantity.value : 0,
                    10
                );
            });
        });
        const deleteRecord = async (slug) => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}cart/delete/${slug}`;
            const response = await common.store({ url, data: {} });
            getRecords();
        };
        const addRecord = async (slug) => {
            const response = common.addProductCart(slug, {})
            response.then((x) => {
                if(x.data.error){
                    common.setMessageError('No pudo agregarse el producto. Revise la disponibilidad.')
                }else{
                    getRecords()
                }
            }).catch((x) => {
                console.log(x)
                alert(x)
                common.setMessageError('No pudo agregarse el producto. Revise la disponibilidad.')
            })

        };
        const getRecords = async () => {
            common.updateCartItems();
        };
        const getDummyImage = () => {
            return `${String(process.env.MIX_APP_HOST)}assets/images/dummy.jpg`;
        };
        const formatCurrency = (amount, currency_id) => {
            return common.formatCurrency(amount, currency_id);
        }
        onMounted(() => {
            storageSet("access_token", sessionId.value);
            getRecords();
            document.addEventListener("DOMContentLoaded", function () {
                const cartAddTriggerElements = document.querySelectorAll(
                    ".modal-trigger-cart"
                );
                const cartDeleteTriggerElements = document.querySelectorAll(
                    ".cart-trigger-delete"
                );
                // Attach an event listener to each modal trigger element
                cartAddTriggerElements.forEach(function (element) {
                    if (element) {
                        element.addEventListener("click", async function (ev) {
                            ev.preventDefault();
                            addRecord(element.getAttribute("data-product-id"));
                        });
                    }
                });
                cartDeleteTriggerElements.forEach(function (element) {
                    if (element) {
                        element.addEventListener("click", async function (ev) {
                            ev.preventDefault();
                            deleteRecord(
                                element.getAttribute("data-product-id")
                            );
                        });
                    }
                });
            });
        });
        const closeModal = () => {
            enableModal.value = false;
        };
        return {
            common,
            items,
            product,
            enableModal,
            itemsQuantity,
            currencies,
            formatCurrency,
            closeModal,
            deleteRecord,
            getDummyImage,
            getSiteURL,
        };
    },
});
</script>
