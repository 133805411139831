<template>
    <div class="card card-checkout mt-2">
        <h5 class="card-title">
            <i class="fa fa-file-o" aria-hidden="true"></i> Costos de envío
        </h5>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="p-2" v-for="(shipp, id1) in shippings" :key="id1">
                        <div><strong>{{ shipp.business }}</strong></div>
                        <p>{{ shipp.description }}</p>
                        <ul>
                            <li v-for="(amount, idx2) in shipp.options" :key="idx2">
                                <label
                                    class="form-check-label"
                                    :for="`amount${amount.code}`"
                                >
                                    <input
                                        type="radio"
                                        name="amountshipping"
                                        v-model="amountShipping"
                                        :id="`amount${amount.code}`"
                                        :value="amount"
                                        style="margin-right: 1rem"
                                        @change="handleChange(amount)"
                                        class="form-check-input"
                                        :checked="amountShipping==null && idx2==0"

                                    />

                                    {{ amount.description }} <b v-if="amount.price > 0"> - ({{
                                        formatCurrency(  amount.price, shipp.currency_id)
                                    }})</b
                                    >
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useCommonStore } from "../../store/useCommonFrontend";
export default defineComponent({
    name: "ShippingAmount",
    props: {
        shippings: {
            type: Array,
            default: []
        },
    },
    components: {

    },
    setup(props, { emit }) {
        const common = useCommonStore();
        const shippings = computed(()=> props.shippings)
        const amountShipping = ref(null)
        const formatCurrency = (amount, currency_id) => {
            return common.formatCurrency(amount, parseInt(currency_id,10));
        };
        const handleChange = (item) => {
            console.log(item)
            amountShipping.value = item;
            console.log(item)
            emit('update', item)
        }

        onMounted(() => {

        });
        return {
            common,
            shippings,
            amountShipping,
            formatCurrency,
            handleChange
        };
    },
});
</script>
