<template>
    <div>
        <!-- Start Modal Add cart -->
        <div
            class="modal fade"
            id="modalAddFavorite"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            >
            <div class="modal-dialog  modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col text-right">
                                    <button type="button"
                                        class="close modal-close"
                                        @click="handleClose"
                                        aria-label="Close">
                                        <span aria-hidden="true">
                                            <i class="fa fa-times"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div v-if="myProduct && myProduct.multimedia && myProduct.multimedia.length > 0" class="modal-add-cart-product-img">
                                                <img class="img-fluid" :src="myProduct.multimedia[0].url" alt="">
                                            </div>
                                            <div v-else class="modal-add-cart-product-img">
                                                <img :src="getPath('assets/images/dummy.jpg')" alt="" class="img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="modal-add-cart-info"><i class="fa fa-check-square"></i>Se ha agregado a favoritos satifactoriamente!</div>
                                            <div class="modal-add-cart-product-cart-buttons">
                                                <a :href="getSiteURL('favoritos')">Ver Favoritos</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 modal-border">
                                    <ul class="modal-add-cart-product-shipping-info">
                                        <li> <strong>
                                            <i class="icon-shopping-cart"></i> Se han agregado productos a favoritos</strong>
                                        </li>
                                        <li class="modal-continue-button">
                                            <a :href="getSiteURL('categorias')">VER TODAS LAS CATEGORÍAS</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal Add cart -->
    </div>
</template>
<script>
import { storeToRefs } from 'pinia'
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import { getSiteURL } from "../utils";
export default defineComponent({
    name: "modalAddFavorite",
    setup() {
        const common = useCommonStore();
        const myProduct = ref({});
        const modal = ref({})
        const total = ref(0)
        const typeAdded = computed(() => {
            return common.state.typeAdded
        });

        const getPath = (image) => {
            return  `${String(process.env.MIX_APP_HOST)}${image}`
        }
        const handleClose = () => {
            common.resetAddition()
        }
        watch(typeAdded, (v1,v2) => {
            if (v1 && v1 === 'favorite') {
                total.value = common.state.cartItems.length
                myProduct.value = common.state.currentProduct;
                modal.value.show();
            }else{
                modal.value.hide();
            }
        })

        onMounted(() => {
            modal.value = new bootstrap.Modal(
                document.getElementById("modalAddFavorite")
            );
            common.resetAddition()
        })


        return {
            common,
            myProduct,
            total,
            getPath,
            handleClose,
            getSiteURL
        };
    },
});
</script>
