<template>
    <div class="card mb-2 card-checkout">
            <h5 class="card-title">
                <i class="fa fa-file-o" aria-hidden="true"></i> Detalles de la Orden
            </h5>
            <div class="card-body">
                <div class="order_table">
                    <table class="table checkout_cart">
                        <thead>
                            <tr>
                                <th>Imagen</th>
                                <th>Producto</th>
                                <th>Cant.</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="offcanvas-cart-image">
                                    <a :href="item.link">
                                        <img
                                            v-if="item.multimedia[0]"
                                            :src="item.multimedia[0].url"
                                            class="offcanvas-cart-image"
                                        />
                                        <img
                                            v-else
                                            :src="getDummyImage()"
                                            alt=""
                                            class="offcanvas-cart-image"
                                        />
                                    </a>
                                </td>
                                <td>
                                    <strong>{{ item.name }}</strong>
                                    <small>({{
                                        formatCurrency(
                                            item.price,
                                            item.currency_id
                                        )
                                    }})</small>
                                </td>
                                <td>
                                    {{ item.quantity }}
                                </td>
                                <td class="text-end">
                                    {{
                                        formatCurrency(
                                            item.final_price,
                                            item.currency_id
                                        )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="items.length > 0">
                            <tr>
                                <th colspan="3">Subtotal</th>
                                <td class="text-end">
                                    {{
                                        formatCurrency(
                                            subtotal,
                                            items[0].currency_id
                                        )
                                    }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useCommonStore } from "../../store/useCommonFrontend";
export default defineComponent({
    name: "DetailsModule",
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        extra: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    setup(props, { emit }) {
        const common = useCommonStore();
        const subtotal = computed(() => {
            var v = 0;
            props.items.map((x) => {
                v += parseFloat(x.price) * parseInt(x.quantity, 10);
            });
            return v;
        });
        const items = computed(() => {
            return props.items.map((x) => {
                return {
                    ...x,
                    final_price: parseFloat(x.price) * parseInt(x.quantity, 10),
                };
            });
        });
        const formatCurrency = (amount, currency_id) => {
            return common.formatCurrency(amount, currency_id);
        };
        const getDummyImage = () => {
            return `${String(process.env.MIX_APP_HOST)}assets/images/dummy.jpg`;
        };
        return {
            items,
            subtotal,
            formatCurrency,
            getDummyImage
        };
    },
});
</script>
