import { notify } from "@kyvg/vue3-notification";

const localId = 'indsud';

export function getCommonHeaders() {
    return {
        headers: { 'content-type': 'application/json' }
    };
}
export function getAuthHeaders() {
    const token = String(storageGet('access_token'));
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return {
        headers: { 'content-type': 'application/json' }
    };
}

export function simpleNotify(error) {
    notify({
        type: error ? 'error' : 'success',
        text: error
            ? 'Error, compruebe los datos ingresados'
            : 'Acción completada con éxito',
    });
}

export function getSiteURL(url) {
    return `${String(process.env.MIX_APP_HOST)}${url}`
}

export function simpleNotifyMessage(message, type) {
    notify({
        type: type ?? 'error',
        title: message ?? 'Se produjo un error. Por favor, vuelva a intentarlo.'
    });
}

export function formatNumber(number, format) {
     // Use a regular expression to match and replace # with integer part and ## with decimal part
     const formattedValue = format.replace(/#/, Math.floor(number)).replace(/##/, (number % 1).toFixed(2).slice(2));
     return formattedValue;
}


export function storageSet(key, payload) {
    const y = localId + '_' + String(key);
    return localStorage.setItem(y, String(payload));
}

export function storageGet(key) {
    const y = localId + '_' + String(key);
    return localStorage.getItem(y);
}

export function storageRemove(key) {
    const y = localId + '_' + String(key);
    return localStorage.removeItem(y);
}

export function dateFromLaravel(dateString) {
    var dateComponents = dateString.split('T')[0].split('-'); // Split the string and get the year, month, and day components
    var year = parseInt(dateComponents[0], 10);
    var month = parseInt(dateComponents[1], 10) - 1; // Months are zero-based in JavaScript (0 = January, 1 = February, etc.)
    var day = parseInt(dateComponents[2], 10);
   return new Date(year, month, day);
}
