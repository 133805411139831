<template>
    <div>
        <template v-if="items.length > 0">
            <ul class="offcanvas-wishlist">
                <li
                    class="offcanvas-wishlist-item-single"
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <div class="offcanvas-wishlist-item-block">
                        <a
                            :href="item.link"
                            class="offcanvas-wishlist-item-image-link"
                        >
                            <img
                                v-if="item.multimedia[0]"
                                :src="item.multimedia[0].url"
                                alt=""
                                class="offcanvas-wishlist-image"
                            />
                            <img
                                v-else
                                :src="getDummyImage()"
                                alt=""
                                class="offcanvas-wishlist-image"
                            />
                        </a>
                        <div class="offcanvas-wishlist-item-content">
                            <a
                                :href="item.link"
                                class="offcanvas-wishlist-item-link"
                                >{{ item.name }}</a
                            >
                        </div>
                    </div>
                    <div class="offcanvas-wishlist-item-delete text-right">
                        <a
                            href="#"
                            class="offcanvas-wishlist-item-delete"
                            @click="deleteRecord(item.slug)"
                            ><i class="fa fa-trash-o"></i
                        ></a>
                    </div>
                </li>
            </ul>
        </template>
        <template v-else>
            <div class="alert alert-info">No hay productos como favoritos</div>
        </template>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import { storageSet } from "../utils";
export default defineComponent({
    props: {
        sessionid: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const common = useCommonStore();
        const items = ref([]);
        const itemsQuantity = ref(0);
        const sessionId = computed(() => props.sessionid);
        const deleteRecord = async (slug) => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}favoritos/delete/productos/${slug}`;
            const response = await common.store({ url, data: {} });
            getRecords();
        };
        const addRecord = async (slug) => {
            const response = common.addProductFavorite(slug)
            response.then((x) => {
                if(x.data.error){
                    alert('Ha ocurrido un error')
                }else{
                    getRecords()
                }
            })
        };
        const getRecords = async () => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}favoritos/productos`;
            const response = await common.store({ url, data: {} });
            itemsQuantity.value = response.data.length;
            const indicators = document.querySelectorAll(
                ".item-count-favorite"
            );
            indicators.forEach(function (element) {
                element.textContent = parseInt(
                    itemsQuantity.value ? itemsQuantity.value : 0,
                    10
                );
            });
            items.value = response.data;
        };
        const getDummyImage = () => {
            return `${String(process.env.MIX_APP_HOST)}assets/images/`;
        };
        onMounted(() => {
            storageSet("access_token", sessionId.value);
            document.addEventListener("DOMContentLoaded", function () {
                const favoriteAddTriggerElements = document.querySelectorAll(
                    ".favorite-trigger-add"
                );
                const favoriteDeleteTriggerElements = document.querySelectorAll(
                    ".favorite-trigger-delete"
                );
                // Attach an event listener to each modal trigger element
                favoriteAddTriggerElements.forEach(function (element) {
                    if (element) {
                        element.addEventListener("click", async function (ev) {
                            ev.preventDefault();
                            addRecord(element.getAttribute("data-product-id"));
                        });
                    }
                });
                favoriteDeleteTriggerElements.forEach(function (element) {
                    if (element) {
                        element.addEventListener("click", async function (ev) {
                            ev.preventDefault();
                            deleteRecord(
                                element.getAttribute("data-product-id")
                            );
                        });
                    }
                });
            });
            getRecords();
        });
        return {
            items,
            deleteRecord,
            getDummyImage,
        };
    },
});
</script>
