<template>

</template>
<script>
import { defineComponent, onMounted, computed } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import { storageSet } from "../utils";
export default defineComponent({
    name: 'CommonSite',
    props: {
        sessionid: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const common = useCommonStore();
        const sessionId = computed(() => props.sessionid);
        const getRecords = async () => {
            common.getCurrencies();
        };
        onMounted(() => {
            storageSet("access_token", sessionId.value);
            getRecords();
        })
        return {
            common,
            sessionId
        }
    }
})
</script>
