<template>
    <div>
        <div v-if="product">
            <!-- Product Variable Single Item -->
            <div class="variable-single-item" v-if="allowBuy">
                <div class="product-stock">
                    <span class="product-stock-in" v-if="stock && stock > 0">
                        <i class="ion-checkmark-circled"></i>
                        Hay disponibilidad
                    </span>
                    <span class="product-stock" v-else>
                        <i class="ion-close"></i>
                        No hay disponibilidad
                    </span>
                </div>
            </div>
            <!-- Product Variable Single Item -->
            <div class="product-details-variable" v-if="allowBuy">
                <div v-if="product.variations && product.variations.length > 0">
                    <h4 class="title">Opciones disponibles</h4>
                    <table class="available-options">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(variation, k) in product.variations"
                                :key="k"
                            >
                                <td class="codigo">{{ variation.sku }}</td>
                                <td>{{ variation.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>&nbsp;</div>
                    <!-- Product Variable Single Item -->
                    <div class="variable-single-item form-group mb-2">
                        <span>Opción</span>
                        <select
                            class="product-variable-size form-control"
                            v-model="selectedVariation"
                        >
                            <option
                                v-for="(variation, k) in product.variations"
                                :key="k"
                                :value="variation.id"
                            >
                                {{ variation.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- Product Variable Single Item -->
                <div class="d-flex align-items-center">
                    <div class="variable-single-item">
                        <span>Cantidad</span>
                        <div class="product-variable-quantity">
                            <v-plus-minus
                                @update="updateQty"
                                :start="1"
                            ></v-plus-minus>
                        </div>
                    </div>
                    <div class="product-add-to-cart-btn">
                        <button
                            type="button"
                            class="modal-trigger-cart"
                            :data-product-id="product.slug"
                            href="#"
                            @click="addCart()"
                            :disabled="allowAdd"
                            style="white-space: nowrap"
                        >
                            + Agregar
                        </button>
                    </div>
                </div>
                <!-- End  Product Details Meta Area-->
                <div>
                    <div class="product-details-text" v-if="totalPrice > 0">
                        <div class="price">{{ totalPriceFormat }}</div>
                    </div>
                </div>
            </div>
            <!-- End Product Variable Area -->
        </div>
        <div v-else>Cargando ...</div>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import vPlusMinus from "./vPlusMinus.vue";
import { storageSet, getSiteURL, formatNumber } from "../utils";
export default defineComponent({
    name: "ProductDetails",
    components: {
        vPlusMinus,
    },
    props: {
        slug: {
            type: String,
            default: "",
        },
        format: {
            type: String,
            default: "",
        },
        buy: {
            type: [String, Number],
            default: 0,
        },
    },
    setup(props, { emit }) {
        const common = useCommonStore();
        const slug = computed(() => props.slug);
        const format = computed(() => props.format);
        const allowBuy = computed(() =>
            parseInt(props.buy, 10) === 1 ? true : false
        );
        const product = ref({});
        const itemsQuantity = ref(0);
        const selectedVariation = ref({});
        const finalPrice = ref(0);
        const stock = ref(0);
        const price = ref(0);
        const quantity = ref(1);
        const totalPrice = ref(0);
        const totalPriceFormat = ref("");
        const allowAdd = ref(true);
        watch(selectedVariation, (newValue, oldValue) => {
            updatePrice();
        });
        watch(quantity, (newValue) => {
            if (newValue < 1) {
                quantity.value = 1;
            }
            updatePrice();
        });
        const getProduct = async () => {
            var url = new URL(
                `${String(process.env.MIX_SENTRY_HOST)}product/${String(
                    slug.value
                )}`
            );
            const response = await common.get({ url: url, data: {} });
            product.value = response.data;
            if (product.value.variations.length > 0) {
                selectedVariation.value = parseInt(
                    product.value.variations[0].id,
                    10
                );
            }
            updatePrice();
        };
        const updatePrice = () => {
            const finalPrices = document.querySelectorAll(".final-price-show");
            if (product.value.variations.length > 0) {
                const index = product.value.variations.findIndex((x) => {
                    return parseInt(x.id, 10) === selectedVariation.value;
                });
                if (index > -1) {
                    finalPrices.forEach((x) => {
                        x.textContent = common.formatCurrency(
                            product.value.variations[index].price,
                            product.value.variations[index].currency_id
                        );
                    });
                    price.value = product.value.variations[index].price;
                    finalPrice.value = common.formatCurrency(
                        product.value.variations[index].price,
                        product.value.variations[index].currency_id
                    );
                    stock.value = product.value.variations[index].stock;
                }
            } else {
                finalPrices.forEach((x) => {
                    x.textContent = common.formatCurrency(
                        product.value.price,
                        product.value.currency_id
                    );
                });
                price.value = product.value.price;
                finalPrice.value = common.formatCurrency(
                    product.value.price,
                    product.value.currency_id
                );
                stock.value = product.value.stock;
            }
            totalPrice.value = price.value * quantity.value;
            totalPriceFormat.value = common.formatCurrency(
                totalPrice.value,
                product.value.currency_id
            );
            if (
                parseInt(stock.value, 10) > 0 &&
                parseInt(stock.value, 10) >= parseInt(quantity.value, 10)
            ) {
                allowAdd.value = false;
            } else {
                allowAdd.value = true;
            }
        };

        const addCart = async () => {
            /*
            var payload = {
                variation_id: null,
                quantity: quantity.value,
            };
            if (!allowAdd.value) {
                if (product.value.variations.length > 0) {
                    payload.variation_id = selectedVariation.value;
                }
                const response = common.addProductCart(product.value.slug, payload)
                response.then((x) => {
                    if(x.data.error){
                        alert('Ha ocurrido un error')
                    }else{
                        getRecords()
                    }
                })

            } else {
                alert("No puede realizarse la transacción");
            }
            */
        };
        const getRecords = async () => {
            common.updateCartItems();
        };
        const updateQty = (payload) => {
            quantity.value = payload.quantity;
        };
        onMounted(() => {
            getProduct();
        });
        return {
            product,
            selectedVariation,
            stock,
            price,
            quantity,
            totalPrice,
            totalPriceFormat,
            allowAdd,
            format,
            allowBuy,
            addCart,
            updatePrice,
            updateQty,
            finalPrice,
            itemsQuantity,
        };
    },
});
</script>
