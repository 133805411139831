import { defineStore } from 'pinia';
import axios from 'axios';
import { getAuthHeaders, getCommonHeaders } from '../utils';

export const useCommonStore = defineStore('common', () => {
    const state = {
        currentProduct: null,
        typeAdded: null,
        errorMessage: '',
        cartItems: [],
        currencies: [],
    };

    const actions = {
        async get({ url }) {
            const headers = getAuthHeaders();
            return await this.fetchData(url, 'get', null, headers);
        },

        async store({ url, data }) {
            const headers = getAuthHeaders();
            return await this.fetchData(url, 'post', data, headers);
        },

        async update({ url, data }) {
            const headers = getAuthHeaders();
            if (data instanceof FormData) {
                data.append('_method', 'PUT');
                headers['Content-Type'] = 'multipart/form-data';
            } else {
                data._method = 'PUT';
                headers['X-HTTP-Method-Override'] = 'PUT';
                headers['Content-Type'] = 'application/json';
            }
            return await this.fetchData(url, 'post', data, headers);
        },

        async edit(url) {
            const headers = getAuthHeaders();
            return await this.fetchData(url, 'get', null, headers);
        },

        async remove(url) {
            const headers = getAuthHeaders();
            return await this.fetchData(url, 'delete', null, headers);
        },

        async load({ url, data }) {
            const headers = getCommonHeaders();
            return await this.fetchData(url, 'post', data, headers);
        },

        async download({ url, data }) {
            const headers = getCommonHeaders();
            const config = { responseType: 'blob', timeout: 30000, ...headers };
            return await this.fetchData(url, 'post', data, config);
        },

        async storeFormData({ url, data }) {
            const headers = getAuthHeaders();
            headers['Content-Type'] = 'multipart/form-data';
            return await this.fetchData(url, 'post', data, headers);
        },

        async updateCartItems() {
            const url = `${String(process.env.MIX_SENTRY_HOST)}cart`;
            const headers = getAuthHeaders();
            const response = await this.fetchData(url, 'post', {});
            this.state.cartItems = response.data;
            return response
        },

        async getCurrencies() {
            const url = `${String(process.env.MIX_SENTRY_HOST)}currencies`;
            const headers = getAuthHeaders();
            const response = await this.fetchData(url, 'get', null, headers);
            this.state.currencies = response.data;
            return response
        },

        async addProductCart(slug, payload) {
            const url = `${String(process.env.MIX_SENTRY_HOST)}cart/add/${slug}`;
            const headers = getAuthHeaders();
            const data = await this.fetchData(url, 'post', payload, headers);
            this.state.currentProduct = data.data.product;
            this.state.typeAdded = 'cart';
            return data
        },

        async addProductFavorite(slug) {
            var url = `${String(process.env.MIX_SENTRY_HOST)}favoritos/add/productos/${slug}`;
            const headers = getAuthHeaders();
            const data = await this.fetchData(url, 'post', {}, headers);
            this.state.currentProduct = data;
            this.state.typeAdded = 'favorite';
            return data
        },

        setMessageError(message) {
            this.state.errorMessage = message;
        },

        resetAddition() {
            this.state.typeAdded = null;
        },

        formatCurrency(amount, currency_id) {
            const currency = this.state.currencies.find((c) => c.id === currency_id);
            if (currency) {
                return `${currency.prefix || ''}${this.formatNumber(amount)}${currency.suffix || ''}`;
            } else {
                return amount;
            }
        },

        formatNumber(number) {
            let numeroFormateado = number.toLocaleString('es-ES', { maximumFractionDigits: 0 });
            numeroFormateado = numeroFormateado.replace(/,/g, '.');
            //let roundedNumber = Math.round(number);
            //return roundedNumber.toLocaleString();
            return numeroFormateado
        },

        async fetchData(url, method, data, headers) {
            try {
                const response = await axios({ method, url, data, headers });
                return response;
            } catch (error) {
                // Handle error here (e.g., show a message or log the error)
                throw error;
            }
        },
    };

    return {
        state,
        ...actions,
    };
});
