<template>
    <div class="card mb-2">
        <div class="card-body">
            <h5 class="card-title">
                <i class="fa fa-file-o" aria-hidden="true"></i> Datos de facturación</h5>
            <div v-if="billings && billings.length > 0">
                <div v-for="(bill, ind2) in billings" :key="ind2">
                    <div class="mb-2" @click="billingSelect(bill)">
                        <div
                            class="row flex justify-content-center align-items-center"
                        >
                            <div class="col-2 text-center">
                                <div class="mt-2 form-group">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        v-model="selectedBilling"
                                        :value="bill.id"
                                        name="billingselect"
                                    />
                                </div>
                            </div>
                            <div class="col-10">
                                <div>
                                    <strong>{{ bill.name }}</strong>
                                </div>
                                <div>
                                    <span v-if="bill.cuit"
                                        ><b>Cuit:</b> {{ bill.cuit }} /
                                    </span>
                                    <span v-if="bill.company"
                                        ><b>Compañia:</b> {{ bill.company }} /
                                    </span>
                                    <span v-if="bill.city"
                                        ><b>Ciudad:</b> {{ bill.city }} /
                                    </span>
                                    <span v-if="bill.provincia"
                                        ><b>Tel.:</b> {{ bill.provincia }} /
                                    </span>
                                    <span v-if="bill.postalcode"
                                        ><b>Cód. Postal:</b>
                                        {{ bill.postalcode }} /
                                    </span>
                                    <span v-if="bill.billing_phone"
                                        ><b>Tel.:</b> {{ bill.billing_phone }} /
                                    </span>
                                    <span v-if="bill.billing_reference"
                                        ><b>Referencia:</b>
                                        {{ bill.billing_reference }} /
                                    </span>
                                    <span v-if="bill.address_reference"
                                        ><b>Dirección:</b>
                                        {{ bill.address_reference }} /
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2" @click="billingSelect(null)">
                    <div
                        class="row flex justify-content-center align-items-center"
                    >
                        <div class="col-2 text-center">
                            <div class="mt-2">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    v-model="selectedBilling"
                                    :value="null"
                                    name="billingselect"
                                />
                            </div>
                        </div>
                        <div class="col-10">
                            <strong>Nuevos datos de Facturación</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedBilling === null">
                <div class=" mb-2">
                    <div class="row" v-if="errors.length > 0">
                        <div class="col-12">
                            <div class="alert alert-danger">
                                <div
                                    v-for="(error, idx) in errors"
                                    :key="idx"
                                >
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <labe >Nombre <span>*</span></labe>
                                <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    v-model="billing.name"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>DNI / CUIT <span>*</span></label>
                                <input
                                    type="text"
                                    name="cuit"
                                    class="form-control"
                                    v-model="billing.cuit"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="default-form-box form-group">
                                <label>Compañia</label>
                                <input
                                    type="text"
                                    name="cuit"
                                    class="form-control"
                                    v-model="billing.company"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="default-form-box form-group">
                                <label>Ciudad <span>*</span></label>
                                <input
                                    placeholder="Ciudad"
                                    type="text"
                                    class="form-control"
                                    v-model="billing.city"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="province"
                                    >Provincia <span>*</span></label
                                >
                                <select
                                    class="form-control"
                                    name="province"
                                    v-model="billing.provincia_id"
                                    @input="updateParentData"
                                >
                                    <option
                                        :value="province.id"
                                        v-for="(
                                            province, ind1
                                        ) in provinces"
                                        :key="ind1"
                                    >
                                        {{ province.nombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="default-form-box form-group">
                                <label>Dirección <span>*</span></label>
                                <input
                                    placeholder="Ingrese la dirección"
                                    type="text"
                                    class="form-control"
                                    v-model="billing.address_reference"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>Teléfono<span>*</span></label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="billing.billing_phone"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label> Código Postal <span>*</span></label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="billing.postalcode"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";

export default defineComponent({
    name: "BillingModule",
    props: {
        provinces: {
            type: Array,
            default: () => {
                return [];
            },
        },
        billings: {
            type: Object,
            default: () => {
                return {};
            },
        },
        errors: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    setup(props, { emit }) {
        const billing = ref({
            id: null,
            provincia_id: null,
            name: null,
            cuit: null,
            company: null,
            city: null,
            postalcode: null,
            billing_phone: null,
            billing_reference: null,
            address_reference: null,
            selectedBilling: null,
        });
        const errors = computed(() => props.errors);
        const billings = computed(() => props.billings);
        const provinces = computed(() => props.provinces);
        const selectedBilling = ref(null);
        watch(props.billings, (value) => {
            if (value.length > 0) {
                billing.value = { ...value[0] };
            }
        });
        const updateParentData = () => {
            emit("update", billing.value);
        };
        const billingSelect = (bill) => {
            if (bill == null) {
                billing.value.id = null;
                selectedBilling.value = null;
                billing.value.selectedBilling = null;
            } else {
                selectedBilling.value = bill.id;
                billing.value.selectedBilling = bill.id;
                billing.value = { ...bill, provincia_id: bill.provincia_id };
            }
        };

        return {
            errors,
            billing,
            billings,
            provinces,
            selectedBilling,
            billingSelect,
            updateParentData,
        };
    },
});
</script>
