require('./bootstrap')
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import vSelect from "vue-select";
import Notifications from '@kyvg/vue3-notification'
import BootstrapVueNext from 'bootstrap-vue-next'
import CommonSite from './site/Common'
import ModalProduct from './site/Modal';
import FavoritePanel from './site/Favorite';
import CartPanel from './site/Cart';
import CheckoutPanel from './site/Checkout';
import ProductDetails from './site/ProductDetails';
import ModalCart from './site/ModalCart';
import ModalError from './site/ModalError';
import ModalFavorite from './site/ModalFavorite';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

// Módulo de coberturas
const app = createApp({
    components: {
        CommonSite,
        ModalProduct,
        FavoritePanel,
        CartPanel,
        ProductDetails,
        CheckoutPanel,
        ModalCart,
        ModalError,
        ModalFavorite
    }
})
app.use(createPinia())
app.use(Notifications)
app.use(BootstrapVueNext)
app.component("v-select", vSelect);
app.mount('#app')
