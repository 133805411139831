<template>
    <div class="d-inline">
    <div class="input-group cart-plus-minus">
        <button
            class="btn dec qtybutton"
            type="button"
            id="button-addon1"
            @click="rest"
        >
            <span class="fa fa-minus"></span>
        </button>
        <input
            type="text"
            class="form-control cart-plus-minus-box"
            placeholder=""
            aria-label=""
            aria-describedby="button-addon1"
            v-model="quantity"
            @change="changeQuantity"
        />
        <button
            class="btn inc qtybutton"
            type="button"
            id="button-addon2"
            @click="add"
        >
            <span class="fa fa-plus"></span>
        </button>
    </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
export default defineComponent({
    name: "vPlusMinus",
    props: {
        start: {
            type: Number,
            default: 1,
        },
    },
    setup(props, { emit }) {
        const start = computed(() => props.start);
        const quantity = ref(1);
        watch(quantity, (v1, v2) => {
            emit('update', { quantity: v1 })
        });
        const add = async () => {
            if(isNaN(quantity.value)){
                quantity.value = 1;
            } else {
                ++quantity.value;
            }

        };
        const rest = () => {
            if(isNaN(quantity.value)){
                quantity.value = 1;
            } else {
                --quantity.value;
                if (quantity.value < 0) {
                    quantity.value = 0;
                }
            }

        };
        const changeQuantity = () => {
            if(isNaN(quantity.value)){
                quantity.value = 1;
            }
        };

        onMounted(() => {});
        return {
            quantity,
            add,
            rest,
            changeQuantity,
        };
    },
});
</script>
