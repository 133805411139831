<template>
    <div class="checkout-section mb-5">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="row" v-if="customer === null">
                    <div class="col-12">
                        <!-- User Quick Action Form -->
                        <login-module :message="loginMessage"></login-module>
                        <!-- User Quick Action Form -->
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        Nombre: <strong>{{ customer.name }}</strong>
                        <br />
                    </div>
                </div>
                <!-- Start User Details Checkout Form -->
                <div class="checkout_form" data-aos="fade-up" data-aos-delay="400">

                    <div class="row">
                        <div class="col-12">
                            <shipping-module
                                :provinces="provinces"
                                :shippings="shippings"
                                :errors="errorsShipping"
                                @update="updateShipping"
                                @change="changeShipping"
                            ></shipping-module>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="p-2">
                                    <div class="form-check">
                                        <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value="1"
                                                id="flexCheckDefault"
                                                v-model="sameAddress"
                                            />

                                            Utilizar la misma dirección para facturación
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="!sameAddress">
                            <billing-module
                                :provinces="provinces"
                                :billings="billings"
                                :errors="errorsBilling"
                                @update="updateBilling"
                            ></billing-module>
                        </div>
                    </div>

                    <div v-if="shippingsAmounts.length > 0 && enableShippingMethods">
                        <shipping-amount
                            :shippings="shippingsAmounts"
                            @update="changeAmountShipping">
                        </shipping-amount>
                    </div>
                    <div class="row" v-if="errorsPayment.length > 0">
                        <div class="col-12">
                            <div class="alert alert-danger">
                                <div v-for="(error, idx) in errorsPayment" :key="idx">
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <payments-list
                        :payments="payments"
                        @update="updatePaymentMethod"
                        v-if="enablePaymentMethods"
                    >
                    </payments-list>
                    <div class="row justify-contents-center align-items-center">
                        <div class="col-12 text-center mt-2">
                            <button
                                class="btn btn-lg w-100 btn-black-default-hover btn-lg btn-primary"
                                type="button"
                                @click="sendInfo"
                                v-if="enablePaymentMethods && enableShippingMethods"
                            >
                                Proceder al pago
                            </button>
                            <div v-else>
                                <p>Debe completar todos los datos para proceder al pago.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <details-module :items="items" :extra="extra"></details-module>
            </div>
        </div>
        <modal-message
            :message="message"
            :messageType="messageType"
            :enable="messageEnable"
            @close="closeMessage"
        ></modal-message>
    </div>
    <!-- ...:::: End Checkout Section:::... -->
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useCommonStore } from "../store/useCommonFrontend";
import { useNotification } from "@kyvg/vue3-notification";
import LoginModule from "./checkout/Login.vue";
import BillingModule from "./checkout/Billing.vue";
import ShippingModule from "./checkout/Shipping.vue";
import DetailsModule from "./checkout/Details.vue";
import PaymentsList from "./checkout/Payments.vue";
import ShippingAmount from "./checkout/ShippingAmount.vue";
import ModalMessage from "./ModalMessage.vue";
export default defineComponent({
    name: "CheckoutPanel",
    props: {
        sessionid: {
            type: String,
            default: "",
        },
    },
    components: {
        LoginModule,
        BillingModule,
        ShippingModule,
        DetailsModule,
        PaymentsList,
        ModalMessage,
        ShippingAmount
    },
    setup(props) {
        const common = useCommonStore();
        const { notify } = useNotification();
        const loginMessage = ref(false);
        const message = ref(null);
        const messageType = ref(null);
        const messageEnable = ref(false);
        const provinces = ref([]);
        const payments = ref([]);
        const customer = ref(null);
        const paymentMethod = ref(null);
        const shippingsAmounts = ref([]);
        const sameAddress = ref(true);
        const selectedShipping = ref(null);
        const billing = ref({
            id: null,
            provincia_id: null,
            name: null,
            cuit: null,
            company: null,
            city: null,
            postalcode: null,
            billing_phone: null,
            billing_reference: null,
            address_reference: null,
            selectedBilling: null,
        });
        const billings = ref(null);
        const shipping = ref({
            id: null,
            name: null,
            email: null,
            provincia_id: null,
            firstname: null,
            dni_cuit: null,
            city: null,
            postalcode: null,
            phone: null,
            address: null,
            address_reference: null,
            selectedShipping: null,
        });
        const shippings = ref(null);
        const extra = ref({});
        const errorsBilling = ref([]);
        const errorsShipping = ref([]);
        const errorsPayment = ref([]);
        const amountShipping = ref(null);

        const enableShippingMethods = ref(false)
        const enablePaymentMethods = ref(false)


        const items = computed(() => {
            return common.state.cartItems;
        });
        //Auxiliares
        const getProvinces = async () => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}checkout/provinces`;
            const response = await common.load({ url, data: {} });
            provinces.value = response.data;
        };
        const getPaymentsMethods = async () => {
            var url = `${String(process.env.MIX_SENTRY_HOST)}checkout/payments`;
            const response = await common.store({ url, data: {} });
            payments.value = response.data;
        };
        const getCustomerInfo = async () => {
            var url = `${String(
                process.env.MIX_SENTRY_HOST
            )}checkout/customerInfo`;
            const response = await common.store({ url, data: {} });
            if (response.data === "") {
                customer.value = null;
                billings.value = null;
                shippings.value = null;
            } else {
                customer.value = response.data;
                billings.value = response.data.billings;
                shippings.value = response.data.shippings;
            }
        };
        const updateBilling = (bill) => {
            billing.value = bill;
        };
        const updateShipping = async (ship) => {
            shipping.value = ship;
            const errors = validateShipping( ship )
            if (errors.length == 0 ) {
                // Cargamos los datos de envio
                var url = `${String(process.env.MIX_SENTRY_HOST)}checkout/shippingcalculation`;
                const payload = ship;
                await common
                    .store({ url, data: payload })
                    .then((x) => {
                        errorsShipping.value = []
                        shippingsAmounts.value = x.data;
                        amountShipping.value = x.data[0] && x.data[0].options[0];
                        enableShippingMethods.value = true
                        enablePaymentMethods.value = true
                    });
            }else{
                errorsShipping.value = errors
            }
        };
        const changeAmountShipping = (amount) => {
            enablePaymentMethods.value = true
            amountShipping.value = amount
        }

        const validateShipping = ( shippingObj ) => {
            const errorsShipping = []
            let cuit = false
            if (
                !(
                    shippingObj.dni_cuit &&
                    shippingObj.dni_cuit !== "" &&
                    shippingObj.dni_cuit !== null
                )
            ) {
                errorsShipping.push("Debe ingresar el dni o cuit");
            }else{
                cuit = validarCUIT(shippingObj.dni_cuit)
            }
            if (
                !(
                    shippingObj.firstname &&
                    shippingObj.firstname !== "" &&
                    shippingObj.firstname !== null
                )
            ) {
                errorsShipping.push("Debe ingresar el nombre");
            }
            if ( !cuit && !(
                    shippingObj.lastname &&
                    shippingObj.lastname !== "" &&
                    shippingObj.lastname !== null
                )
            ) {
                errorsShipping.push("Debe ingresar el apellido");
            }
            if (
                !(
                    shippingObj.email &&
                    shippingObj.email !== "" &&
                    shippingObj.email !== null
                )
            ) {
                errorsShipping.push("Debe ingresar el correo electrónico");
            }

            if (
                !(
                    shippingObj.city &&
                    shippingObj.city !== "" &&
                    shippingObj.city !== null
                )
            ) {
                errorsShipping.push("Debe ingresar la ciudad");
            }
            if (
                !(
                    shippingObj.provincia_id &&
                    shippingObj.provincia_id !== "" &&
                    shippingObj.provincia_id !== null
                )
            ) {
                errorsShipping.push("Debe ingresar la provincia");
            }
            if (
                !(
                    shippingObj.address &&
                    shippingObj.address !== "" &&
                    shippingObj.address !== null
                )
            ) {
                errorsShipping.push("Debe ingresar la dirección");
            }

            if (
                !(
                    shippingObj.phone &&
                    shippingObj.phone !== "" &&
                    shippingObj.phone !== null
                )
            ) {
                errorsShipping.push("Debe ingresar un teléfono");
            }
            if (
                !(
                    shippingObj.postalcode &&
                    shippingObj.postalcode !== "" &&
                    shippingObj.postalcode !== null
                )
            ) {
                errorsShipping.push("Debe ingresar el código postal");
            }
            return errorsShipping
        }

        function validarCUIT(cuit) {
            cuit = cuit.toString().replace(/[-\s]/g, '');
            // Verificar que el CUIT tenga 11 dígitos
            if (cuit.length !== 11) {
                return false;
            }
            // Verificar que todos los caracteres sean dígitos
            if (!/^\d{11}$/.test(cuit)) {
                return false;
            }
            // Calcular el dígito verificador
            const coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
            let suma = 0;
            for (let i = 0; i < 10; i++) {
                suma += parseInt(cuit[i]) * coeficientes[i];
            }
            const resto = suma % 11;
            const digitoVerificadorCalculado = resto === 0 ? 0 : resto === 1 ? 9 : 11 - resto;
            // Comparar el dígito verificador calculado con el dígito verificador del CUIT
            return digitoVerificadorCalculado === parseInt(cuit[10]);
        }

        const validate = () => {
            errorsBilling.value = [];
            errorsShipping.value = [];
            errorsPayment.value = [];
            if (selectedShipping.value !== null) {
            } else {
                errorsShipping.value = validateShipping(shipping.value);
            }
            if (!sameAddress.value) {
                if (billing.value.selectedBilling !== null) {
                    billing.value.id = billing.value.selectedBilling;
                } else {
                    billing.value.id = null;
                    if (
                        !(
                            billing.value.firstname &&
                            billing.value.firstname !== "" &&
                            billing.value.firstname !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar el nombre");
                    }
                    if (
                        !(
                            billing.value.cuit &&
                            billing.value.cuit !== "" &&
                            billing.value.cuit !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar el cuit o Dni");
                    }
                    if (
                        !(
                            billing.value.city &&
                            billing.value.city !== "" &&
                            billing.value.city !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar la ciudad");
                    }
                    if (
                        !(
                            billing.value.postalcode &&
                            billing.value.postalcode !== "" &&
                            billing.value.postalcode !== null
                        )
                    ) {
                        errorsBilling.value.push(
                            "Debe ingresar el código postal"
                        );
                    }
                    if (
                        !(
                            billing.value.billing_phone &&
                            billing.value.billing_phone !== "" &&
                            billing.value.billing_phone !== null
                        )
                    ) {
                        errorsBilling.value.push("Debe ingresar el teléfono");
                    }
                    if (
                        !(
                            billing.value.provincia_id &&
                            billing.value.provincia_id !== "" &&
                            billing.value.provincia_id !== null
                        )
                    ) {
                        errorsBilling.value.push(
                            "Debe seleccionar la provincia"
                        );
                    }
                }
            }
            if (amountShipping.value === null) {
                errorsShipping.value.push(
                    "Debe seleccionar una empresa para el envío"
                );
            }
            if (paymentMethod.value === null &&  payments.value.length > 1) {
                errorsPayment.value.push("Debe seleccionar un método de pago");
            }
            if (
                errorsBilling.value.length > 0 ||
                errorsShipping.value.length > 0 ||
                errorsPayment.value.length > 0
            ) {

                return false;
            }
            return true;
        };
        const sendInfo = async () => {
            if (validate()) {
                const payment2 = (paymentMethod.value) ? paymentMethod.value : payments.value[0].id
                const payload = {
                    billing: billing.value,
                    shipping: shipping.value,
                    sameaddress: sameAddress.value,
                    payment: payment2,
                    shippingcost: amountShipping.value,
                };
                var url = `${String(process.env.MIX_SENTRY_HOST)}checkout/init`;
                const response = await common
                    .store({ url, data: payload })
                    .then((x) => {
                        if (x.data.status == "success") {
                            if (x.data.data.redirect !== "") {
                                window.location.href = x.data.data.redirect;
                            } else {
                                messageEnable.value = true;
                                messageType.value = "success";
                                message.value =
                                    "Se ha registrado la compra satisfactoriamente";
                            }
                        } else {
                            messageEnable.value = true;
                            messageType.value = "error";
                            message.value =
                                "No ha podido procesarse la compra revise los datos";
                        }
                    });
            } else {
                messageEnable.value = true;
                messageType.value = "error";
                message.value =
                    "Revise los errores encontrados para poder finalizar su compra";
            }
        };
        const updatePaymentMethod = (payload) => {
            paymentMethod.value = payload.id;
        };

        const formatCurrency = (amount, currency_id) => {
            return common.formatCurrency(amount, parseInt(currency_id,10));
        };
        const changeShipping = (value) => {
            selectedShipping.value = value.id;
            if(shippings.value){
                const index = shippings.value.findIndex(
                    (x) =>
                        parseInt(x.id, 10) === parseInt(selectedShipping.value, 10)
                );
                if (index > -1) {
                    shipping.value.id = shippings.value[index].id;
                    shipping.value.postalcode = shippings.value[index].postalcode;
                    updateShipping(shipping.value);
                }
            }

        };
        const closeMessage = () => {
            messageEnable.value = false;
        };

        onMounted(() => {
            getCustomerInfo();
            getProvinces();
            getPaymentsMethods();
        });
        return {
            extra,
            items,
            billing,
            billings,
            shipping,
            shippings,
            provinces,
            customer,
            errorsBilling,
            errorsShipping,
            errorsPayment,
            loginMessage,
            payments,
            shippingsAmounts,
            amountShipping,
            sameAddress,
            selectedShipping,
            message,
            messageType,
            messageEnable,
            enableShippingMethods,
            enablePaymentMethods,
            getCustomerInfo,
            updateBilling,
            updateShipping,
            getPaymentsMethods,
            updatePaymentMethod,
            sendInfo,
            formatCurrency,
            changeShipping,
            closeMessage,
            changeAmountShipping
        };
    },
});
</script>
