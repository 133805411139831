<template>
    <div class="card card-checkout mt-2">
        <h5 class="card-title">
                <i class="fa fa-dollar" aria-hidden="true"></i>  Métodos de pago
            </h5>
        <div class="card-body">

            <div v-if="payments && payments.length > 1" class="payments">
                <div
                    class="form-check d-flex align-items-center"
                    v-for="(payment, index) in payments"
                    :key="index"
                >
                    <input
                        type="radio"
                        :id="`paymentm${index}`"
                        name="payment"
                        :value="payment.id"
                        v-model="selectedPayment"
                        @input="updateParentData(payment.id)"
                        style="width: auto; margin-right: 1rem"
                    />
                    <img :src="payment.logo" v-if="payment.logo" class="logo">
                    <label class="form-check-label" :for="`paymentm${index}`">

                        <h5>{{ payment.name }}</h5>
                        <p>{{ payment.description }}</p>
                    </label>
                </div>
            </div>
            <div v-else >
               <div v-if="(typeof payments[0] !== 'undefined')">
                    <div class="form-check d-flex align-items-center">
                        <img :src="payments[0].logo" v-if="payments[0].logo" class="logo">
                        <h4>{{ payments[0].name }}</h4>
                        <p>{{ payments[0].description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";

export default defineComponent({
    props: {
        payments: {
            type: Array,
            default: [],
        },
    },
    setup(props, { emit }) {
        const selectedPayment = ref(null);
        const payments = computed(() => props.payments);
        const updateParentData = (id) => {
            emit("update", { id: id });
        };
        return {
            payments,
            selectedPayment,
            updateParentData,
        };
    },
});
</script>
