<template>
    <div class="card mb-2">
        <div class="card-body">
            <h5 class="card-title">
                <i class="fa fa-file-o" aria-hidden="true"></i> Datos de usuario
            </h5>
            <p  class="card-text">¿Es un usuario registrado?</p>
            <a
                    class="btn btn-black-default-hover"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#checkout_login"
                    aria-expanded="true"
                    >Haga click aquí para autenticarse</a
                >
            <div
                class="row collapse"
                id="checkout_login"
                data-parent="#checkout_login"
            >
                <div class="col-12">
                    <div>
                        <div class="">
                            <p>
                                Si ha comprado con nosotros antes, ingrese sus
                                datos en los cuadros a continuación.
                            </p>
                            <p>
                                Si usted es un cliente nuevo, proceda a la
                                sección de Facturación y Sección de envíos.
                            </p>
                            <div class="alert alert-danger" v-if="myMessage">
                                Los datos ingresados son incorrectos por favor
                                verifique
                            </div>
                            <form :action="urlLogin" method="post">
                                <input
                                    type="hidden"
                                    name="_token"
                                    :value="csrfToken"
                                />
                                <div class="form_group default-form-box">
                                    <label>Email <span>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="email"
                                        v-model="username"
                                    />
                                </div>
                                <div class="form_group default-form-box">
                                    <label>Contraseña <span>*</span></label>
                                    <input
                                        type="password"
                                        name="password"
                                        class="form-control"
                                        v-model="password"
                                    />
                                </div>
                                <div
                                    class="form_group group_3 default-form-box"
                                >
                                    <button
                                        class="btn btn-md btn-black-default-hover"
                                        type="submit"
                                    >
                                        Autenticarse
                                    </button>
                                </div>
                                <a href="customer/password/reset"
                                    >¿Olvidó su contraseña?</a
                                >
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";

export default defineComponent({
    name: "LoginModule",
    props: {
        message: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const urlLogin = ref("");
        const csrfToken = ref("");
        const username = ref(null);
        const password = ref(null);
        const imageLogin = ref(null);
        const myMessage = computed(() => props.message);
        const login = () => {
            emit("login", {
                email: username.value,
                password: password.value,
            });
        };
        onMounted(() => {
            imageLogin.value = `${String(process.env.MIX_APP_HOST)}images/login.jpg`;
            csrfToken.value = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content");
            urlLogin.value = `${String(
                process.env.MIX_APP_HOST
            )}customer/login`;
        });
        return {
            myMessage,
            urlLogin,
            csrfToken,
            username,
            password,
            imageLogin,
            login,
        };
    },
});
</script>
