<template>
    <div class="card mb-2">
        <div class="card-body">
            <h5 class="card-title">
                <i class="fa fa-file-o" aria-hidden="true"></i> Datos de envío
            </h5>

            <div v-if="shippings && shippings.length > 0">
                <div v-for="(ship, ind2) in shippings" :key="ind2">
                    <div @click="shippingSelect(ship)">
                        <div
                            class="row flex justify-content-center align-items-center"
                        >
                            <div class="col-1 text-center">
                                <div class="mt-2">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        v-model="selectedShipping"
                                        :value="ship.id"
                                        name="shippingselected"
                                    />
                                </div>
                            </div>
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-6">
                                        <div>
                                            <strong>{{
                                                ship.receiver_reference
                                            }}</strong>
                                        </div>
                                        <div v-if="ship.receiver_dni">
                                            <b>Dni:</b>
                                            {{ ship.receiver_dni }}
                                        </div>
                                        <div v-if="ship.address">
                                            <b>Dirección:</b>
                                            {{ ship.address }}
                                        </div>
                                        <div v-if="ship.receiver_phone">
                                            <b>Tel.:</b>
                                            {{ ship.receiver_phone }}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div v-if="ship.city">
                                            <b>Ciudad:</b> {{ ship.city }}
                                        </div>
                                        <div v-if="ship.provincia">
                                            <b>Provincia.:</b>
                                            {{ ship.provincia }}
                                        </div>
                                        <div v-if="ship.postalcode">
                                            <b>Cód. Postal:</b>
                                            {{ ship.postalcode }}
                                        </div>
                                        <div v-if="ship.address_reference">
                                            <b>Dirección de referencia:</b>
                                            {{ ship.address_reference }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2" @click="shippingSelect(null)">
                    <div
                        class="row flex justify-content-center align-items-center"
                    >
                        <div class="col-1 text-center">
                            <div class="mt-2">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    v-model="selectedShipping"
                                    :value="null"
                                    name="shippingselected"
                                />
                            </div>
                        </div>
                        <div class="col-11">
                            <strong>Nuevos datos de envío</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedShipping === null">
                <div class="mb-2">
                    <div class="row" v-if="errors.length > 0">
                        <div class="col-12">
                            <div class="alert alert-danger">
                                <div v-for="(error, idx) in errors" :key="idx">
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>Nombre <span>*</span></label>
                                <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    v-model="shipping.receiver_reference"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>Email <span>*</span></label>
                                <input
                                    type="text"
                                    name="email"
                                    class="form-control"
                                    v-model="shipping.email"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>DNI / CUIT <span>*</span></label>
                                <input
                                    type="text"
                                    name="cuit"
                                    class="form-control"
                                    v-model="shipping.receiver_dni"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="default-form-box form-group">
                                <label>Ciudad <span>*</span></label>
                                <input
                                    placeholder="Ciudad"
                                    type="text"
                                    class="form-control"
                                    v-model="shipping.city"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="province"
                                    >Provincia <span>*</span></label
                                >
                                <select
                                    class="form-control"
                                    name="province"
                                    v-model="shipping.provincia_id"
                                    @input="updateParentData"
                                >
                                    <option
                                        :value="province.id"
                                        v-for="(province, ind1) in provinces"
                                        :key="ind1"
                                    >
                                        {{ province.nombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="default-form-box form-group">
                                <label>Dirección <span>*</span></label>
                                <input
                                    placeholder="Ingrese la dirección"
                                    type="text"
                                    class="form-control"
                                    v-model="shipping.address"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>Teléfono<span>*</span></label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="shipping.receiver_phone"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="default-form-box form-group">
                                <label>Código Postal <span>*</span></label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="shipping.postalcode"
                                    @input="updateParentData"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="default-form-box form-group">
                                <label
                                    >Referencia de la Dirección
                                    <span>*</span></label
                                >
                                <textarea
                                    placeholder="Ingrese una referencia para la dirección"
                                    rows="4"
                                    class="form-control"
                                    v-model="shipping.address_reference"
                                    @input="updateParentData"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";

export default defineComponent({
    name: "ShippingModule",
    props: {
        provinces: {
            type: Array,
            default: () => {
                return [];
            },
        },
        shippings: {
            type: Object,
            default: () => {
                return {};
            },
        },
        errors: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    setup(props, { emit }) {
        const shipping = ref({
            id: null,
            name: null,
            email: null,
            provincia_id: null,
            receiver_reference: null,
            receiver_dni: null,
            city: null,
            postalcode: null,
            receiver_phone: null,
            address: null,
            address_reference: null,
            selectedShipping: null,
        });
        const errors = computed(() => props.errors);
        const shippings = computed(() => props.shippings);
        const provinces = computed(() => props.provinces);
        const selectedShipping = ref(null);
        watch(props.shipping, (value) => {
            if (value.length > 0) {
                shipping.value = { ...value[0] };
            }
        });
        watch(selectedShipping, (value) => {
            if (typeof value !== "undefined") {
                emit("change", { id: value });
            }
        });
        const updateParentData = () => {
            emit("update", shipping.value);
        };

        const shippingSelect = (ship) => {
            if (ship == null) {
                shipping.value.id = null;
                selectedShipping.value = null;
                shipping.value.selectedShipping = null;
            } else {
                selectedShipping.value = ship.id;
                shipping.value.selectedShipping = ship.id;
                shipping.value = { ...ship, provincia_id: ship.provincia_id };
            }
        };

        return {
            errors,
            shipping,
            shippings,
            provinces,
            selectedShipping,
            updateParentData,
            shippingSelect,
        };
    },
});
</script>
