<template>
    <div class="card mb-2">
        <div class="card-body">
            <h5 class="card-title">
                <i class="fa fa-file-o" aria-hidden="true"></i> Detalles de la Orden</h5>

            <div class="order_table">
                <table class="table-striped table">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td>
                                {{ item.name }}
                                <strong> × {{ item.quantity }}</strong>
                            </td>
                            <td class="text-end">
                                {{
                                    formatCurrency(
                                        item.final_price,
                                        item.currency_id
                                    )
                                }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot v-if="items.length > 0">
                        <tr>
                            <th>Subtotal</th>
                            <td class="text-end">
                                {{
                                    formatCurrency(
                                        subtotal,
                                        items[0].currency_id
                                    )
                                }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useCommonStore } from "../../store/useCommonFrontend";
export default defineComponent({
    name: "DetailsModule",
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        extra: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    setup(props, { emit }) {
        const common = useCommonStore();
        const subtotal = computed(() => {
            var v = 0;
            props.items.map((x) => {
                v += parseFloat(x.price) * parseInt(x.quantity, 10);
            });
            return v;
        });
        const items = computed(() => {
            return props.items.map((x) => {
                return {
                    ...x,
                    final_price: parseFloat(x.price) * parseInt(x.quantity, 10),
                };
            });
        });
        const formatCurrency = (amount, currency_id) => {
            return common.formatCurrency(amount, currency_id);
        };
        return {
            items,
            subtotal,
            formatCurrency,
        };
    },
});
</script>
