<template>
    <div>
        <!-- Start Modal Add cart -->
        <div
            class="modal fade"
            id="modalMessage"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            >
            <div class="modal-dialog  modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <h3 class="col" v-if="messageType=='error'">Mensaje de error</h3>
                                <h3 class="col" v-else>Mensaje de notificación</h3>
                                <div class="col text-right">
                                    <button type="button"
                                        class="close modal-close"
                                        @click="handleClose"
                                        aria-label="Close">
                                        <span aria-hidden="true">
                                            <i class="fa fa-times"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-center" >
                                    <br>
                                    <div class="alert alert-success" v-if="messageType!=='error'">
                                        <p></p>
                                        <h3>{{  message  }}</h3>
                                        <p></p>
                                    </div>
                                    <div class="alert alert-danger" v-else>
                                        <p></p>
                                        <h3>{{  message  }}</h3>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal Add cart -->
    </div>
</template>
<script>
import { defineComponent, computed, ref, onMounted, watch } from "vue";
export default defineComponent({
    name: "ModalMessage",
    props: {
        enable : {
            type: Boolean,
            default: false
        },
        message : {
            type: String,
            default: ''
        },
        messageType : {
            type: String,
            default: 'success'
        },
    },
    setup(props , { emit }) {
        const modal = ref(null);
        const message = computed(() => props.message);
        const messageType = computed(() => props.messageType);
        const enabled = computed(() => props.enable);

        watch(enabled, (v1,v2) => {
            if (v1 ) {
                modal.value.show();
            }else{
                modal.value.hide();
            }
        })

        onMounted(() => {
            document.addEventListener("DOMContentLoaded", function () {
                modal.value = new bootstrap.Modal(
                    document.getElementById("modalMessage")
                );
            })
        })
        const handleClose = () => {
            emit("close");
        }
        return {
            modal,
            message,
            messageType,
            enabled,
            handleClose
        };
    },
});
</script>
